import React from "react"
import { Helmet } from "react-helmet"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link, withPrefix } from "gatsby"
import useScript from "../hooks/useScript"

import "bootstrap/dist/css/bootstrap.min.css"
import "../css/designerd.css"
import "../css/normalize.css"
import "../css/main.css"
import "../css/fontawesome.css"
import "../css/solid.min.css"

import slide1 from "../../static/images/redislide1.jpg"
import slide2 from "../../static/images/redislide2.jpg"
import slide3 from "../../static/images/redislide3.jpg"

export default function Layout({
  children,
  isFrontpage = false,
  bodyClass = "redi",
  heroTitle,
  heroText,
  bcText,
}) {
  useScript(withPrefix("scripts/jquery-3.5.1.min.js"))
  useScript(withPrefix("scripts/main.js"))

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: bodyClass,
        }}
      >
        <title>REDi Engineering</title>
        {/* <script
          src={withPrefix("scripts/jquery-3.5.1.min.js")}
          type="text/javascript"
          async={false}
        />
        <script
          src={withPrefix("scripts/main.js")}
          type="text/javascript"
          async={false}
        /> */}
      </Helmet>
      <header>
        <section className="topbar">
          <Container>
            <Row>
              <Col sm={12}>
                <p>This website is currently under maintenance.</p>
              </Col>
            </Row>
          </Container>
        </section>
        {isFrontpage && (
          <>
            <div
              className="background-img backactive z-show"
              id="back1"
              style={{ backgroundImage: `url(${slide1})` }}
            ></div>
            <div
              className="background-img"
              id="back2"
              style={{ backgroundImage: `url(${slide2})` }}
            ></div>
            <div
              className="background-img"
              id="back3"
              style={{ backgroundImage: `url(${slide3})` }}
            ></div>
          </>
        )}
        {"research" === bodyClass && (
          <video autoPlay muted loop id="research-vid">
            <source src="images/research.mp4" type="video/mp4" />
          </video>
        )}

        <section className="mobile-header">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="logo logo-mobile">
                  <Link to="/">
                    <img src="/images/logo.svg" alt="logo" />
                  </Link>
                </div>
                <Link to="#" className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="header">
          <Container>
            <Row>
              <Col md={12} lg={3}>
                <div className="logo">
                  <Link to="/">
                    <img src="/images/logo.svg" alt="logo" />
                  </Link>
                </div>
              </Col>
              <Col md={12} lg={9}>
                <nav className="menu">
                  <ul className="navigation">
                    <li>
                      <Link activeClassName="mainactive" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" activeClassName="mainactive">
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to="/services" activeClassName="mainactive">
                        What we do
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/projects" activeClassName="mainactive">
                        Projects
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/research" activeClassName="mainactive">
                        Research
                      </Link>
                    </li>
                    <li>
                      <Link to="/tools" activeClassName="mainactive">
                        REDI Tools
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" activeClassName="mainactive">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </section>
        {isFrontpage ? (
          <section className="slide">
            <Container>
              <Row className="content">
                <Col sm={12}>
                  <ul className="slides">
                    <li className="slide-item active" id="slide-1">
                      <h1>
                        REDifine
                        <br />
                        your limits
                      </h1>
                      <Link className="arrow-button arrow-white" to="/services">
                        <span>OUR SERVICES</span>
                      </Link>
                    </li>
                    <li className="slide-item" id="slide-2">
                      <h1>
                        REDI to think
                        <br />
                        out-of-the box
                      </h1>
                      <Link className="arrow-button arrow-white" to="/services">
                        <span>OUR SERVICES</span>
                      </Link>
                    </li>
                    <li className="slide-item" id="slide-3">
                      <h1>
                        Our innovation engine
                        <br />
                        is REDI for use
                      </h1>
                      <Link className="arrow-button arrow-white" to="/services">
                        <span>OUR SERVICES</span>
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col sm={12}>
                  <div className="slidenav">
                    <ul>
                      <li id="nav1" className="navactive">
                        <button data-slide="1">
                          <img src="/images/re.svg" alt="slider navigation" />
                          silience
                        </button>
                      </li>
                      <li id="nav2">
                        <button data-slide="2">
                          <img src="/images/d.svg" alt="slider navigation" />
                          esign
                        </button>
                      </li>
                      <li id="nav3">
                        <button data-slide="3">
                          <img src="/images/i.svg" alt="slider navigation" />
                          nnovation
                        </button>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        ) : (
          <section className="slide">
            <Container>
              <Row className="content">
                <Col sm={12}>
                  <div className="header-title">
                    <div className="header-text">
                      <h1>{heroTitle}</h1>
                      {heroText && <p>{heroText}</p>}
                    </div>
                    <div className="breadcrumbs">
                      <Link to="/">HOME</Link> / {bcText}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </header>
      <main>{children}</main>
      <section className="home-contact">
        <Container>
          <Row className="contact-image">
            <Col sm={12}>
              <div className="contact-bg">
                <img src="/images/contactbg.jpg" alt="" />
                <Link
                  className="email-contact"
                  to="mailto:info@rediengineering.gr"
                >
                  info@rediengineering.gr{" "}
                </Link>
              </div>
            </Col>
          </Row>
          <div className="contact-wrapper">
            <h2>Want to discuss a project?</h2>
            <p>
              Send us a message and we’ll get back to you as soon as possible.
            </p>
            <Link to="mailto:info@rediengineering.gr" className="square-button">
              CONTACT US <span className="fas fa-chevron-right"></span>
            </Link>
            <img src="/images/logo_black.svg" alt="log" />
          </div>
        </Container>
      </section>
      <footer>
        <Container>
          <Row>
            <Col sm={4}>
              <span>2022@ REDi Engineering</span>
            </Col>
            <Col sm={4}></Col>
            <Col sm={4}>
              Created by{" "}
              <a href="https://designerd.gr" target="_blank" rel="noreferrer">
                DESIGNERD
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* <Helmet>
        
      </Helmet> */}
    </>
  )
}
